import React from 'react'
import Slider from 'react-slick'
//import BannerImage from '../../assets/Images/Homepage/homepage-banner-image.png';
import DesktopBannerDoctor from '../../assets/Images/Homepage/Desktop_Doctor.jpg'
import DesktopBannerAmbulance from '../../assets/Images/Homepage/desktop_ambBanner.jpg'
import DesktopBannerHomecare from '../../assets/Images/Homepage/desktop_HomeCare.jpg'
import DesktopBannerPatho from '../../assets/Images/Homepage/Desktop_PathologyDiagnosis.jpg'
import DesktopBannerMedicalTourism from '../../assets/Images/Homepage/Desktop_mediBanner.jpg'
import DesktopBannerHospital from '../../assets/Images/Homepage/desktop_Hospital.jpg'

import MobBannerDoctor from '../../assets/Images/Homepage/Mobile_Doctor.jpg'
import MobBannerAmbulance from '../../assets/Images/Homepage/Mobile_Ambulance.jpg'
import MobBannerHomecare from '../../assets/Images/Homepage/Mobile_HomeCare.jpg'
import MobBannerPatho from '../../assets/Images/Homepage/Mobile_PathologyDiagnosis.jpg'
import MobBannerMedicalTourism from '../../assets/Images/Homepage/Mobile_MedicalTourism.jpg'
import MobBannerHospital from '../../assets/Images/Homepage/Mobile_Hospital.jpg'

import TabBannerDoctor from '../../assets/Images/Homepage/tab_Doctor.jpg'
import TabBannerHospital from '../../assets/Images/Homepage/tab_hospital.jpg'
import TabBannerPatho from '../../assets/Images/Homepage/tab_PathologyDiagnosis.jpg'
import TabBannerAmb from '../../assets/Images/Homepage/tab_ambBanner.jpg'
import TabBannerHmc from '../../assets/Images/Homepage/tab_HomeCare.jpg'
import TabBannerMT from '../../assets/Images/Homepage/tab_mediBanner.jpg'

import '../../../node_modules/slick-carousel/slick/slick.css'
import '../../../node_modules/slick-carousel/slick/slick-theme.css'
import './graphicsBanner.scss'

export default function HomepageBanner() {
  var settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    cssEase: 'linear',
    arrows: false,
  }
  return (
    <div className="homepage-banner">
      <Slider {...settings}>
        <div>
          <img
            src={DesktopBannerHospital}
            alt="Doctor-Banner"
            className="display-lg img-fluid w-100"
          ></img>
          <img
            src={TabBannerHospital}
            alt="Doctor-Banner"
            className="display-md img-fluid w-100"
          ></img>
          <div className="banner-content doctor-banner">
            <div>
              <h1>Schedule A Doctor's Appointment</h1>
            </div>
            <div className="subheading">
              <span>Doctors are always ready to help you 24x7, 365 Days</span>
            </div>
          </div>
        </div>
        <div>
          <img
            src={DesktopBannerDoctor}
            alt="Doctor-Banner"
            className="display-lg img-fluid w-100"
          ></img>
          <img
            src={TabBannerDoctor}
            alt="Doctor-Banner"
            className="display-md img-fluid w-100"
          ></img>
          <div className="banner-content">
            <div>
              <h1>Compassionate Care, Seamless Admission.</h1>
            </div>
            <div className="subheading">
              <span>
                Can't find a bed in any hospital or nursing home? Don't worry,
                Rever Health Solutions ensures your emergency admission with our
                trusted hospital chain.
              </span>
            </div>
          </div>
        </div>

        <div>
          <img
            src={DesktopBannerPatho}
            alt="Doctor-Banner"
            className="display-lg img-fluid w-100"
          ></img>
          <img
            src={TabBannerPatho}
            alt="Doctor-Banner"
            className=" display-md img-fluid w-100"
          ></img>
          <div className="banner-content">
            <div>
              <h1>Accurate Results, Healthier Lives</h1>
            </div>
            <div className="subheading">
              <span>
                Get accurate results at an affordable price! We partner with top
                pathology and diagnostic centers to provide quick and precise
                tests.
              </span>
            </div>
          </div>
        </div>
        <div>
          <img
            src={DesktopBannerAmbulance}
            alt="Doctor-Banner"
            className="display-lg img-fluid w-100"
          ></img>
          <img
            src={TabBannerAmb}
            alt="Doctor-Banner"
            className=" display-md img-fluid w-100"
          ></img>
          <div className="banner-content">
            <div>
              <h1>Your Lifeline in Critical Moments.</h1>
            </div>
            <div className="subheading">
              <span>
                Rapidly responding with critical care to ensure safety and swift
                transport during emergencies.
              </span>
            </div>
          </div>
        </div>
        <div>
          <img
            src={DesktopBannerHomecare}
            alt="Doctor-Banner"
            className="display-lg img-fluid w-100"
          ></img>
          <img
            src={TabBannerHmc}
            alt="Doctor-Banner"
            className="display-md img-fluid w-100"
          ></img>
          <div className="banner-content">
            <div>
              <h1>Expert Care in the Comfort of Your Home</h1>
            </div>
            <div className="subheading">
              <span>
                Convenient home care medical services, doctors, blood tests,
                x-rays, physiotherapy, nursing, medical equipment, and more,
                delivered to your doorstep.
              </span>
            </div>
          </div>
        </div>
        <div>
          <img
            src={DesktopBannerMedicalTourism}
            alt="Doctor-Banner"
            className="display-lg img-fluid w-100"
          ></img>
          <img
            src={TabBannerMT}
            alt="Doctor-Banner"
            className="display-md img-fluid w-100"
          ></img>
          <div className="banner-content">
            <div>
              <h1>World-Class Care, Global Reach.</h1>
            </div>
            <div className="subheading">
              <span>
                Embark on a journey for superior medical care, tailored to your
                needs, across global destinations.
              </span>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  )
}
