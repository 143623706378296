import React, { useState } from "react";
import Header from "../Layout/Header";
import HomepageBanner from "../UI/Banner/HomepageBanner";
import BestServiceLayout from "../FeatureComponent/BestServiceLayout";
import OfferSubscription from "../FeatureComponent/Offer/OfferSubscription";
import Footer from "../Layout/Footer";
import CommonModal from "../UI/UIComponent/Modal/CommonModal";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TopSpeciality from "../FeatureComponent/TopSpeciality/TopSpeciality";
import StaticBanner from "../FeatureComponent/StaticBanner/staticBanner";
import BestHospital from "../FeatureComponent/BestService/BestHospital";
import BestPathology from "../FeatureComponent/BestService/BestPathology";
import HomeCareStaticBanner from "../FeatureComponent/StaticBanner/homeCareStaticBanner";
import HomeCareService from "../FeatureComponent/BestService/HomeCareService";
import AmbulanceService from "../FeatureComponent/BestService/AmbulanceService";
import FeedbackModule from "../FeatureComponent/Feedback/FeedbackModule";
import BestDoctor from "../FeatureComponent/BestService/BestDoctor";
import OtpModal from "../UI/UIComponent/Modal/OtpModal";
import FloatingWidget from "../UI/Floating/FloatingWidget";
import "./Page-style/homePage.scss";

export default function HomepageModule(props) {
  const [patientPayload,setpatientPayload] = useState({});
  const [encodedData, setEncodeddata] = useState();
  const handleCallback = (childData) =>{
    setpatientPayload({...childData});
    setEncodeddata(childData.encodedData)
  }
    return (
    <div>
      <Header></Header>
      <CommonModal></CommonModal>
      <OtpModal patientInfoPayload = {patientPayload} patientEncodedData = {encodedData}></OtpModal>
      <section>
        <HomepageBanner></HomepageBanner>
        {/* <FloatingWidget parentCallback={handleCallback}></FloatingWidget> */}
        <div className="homePage-bodyContent">
          <BestServiceLayout></BestServiceLayout>
          <TopSpeciality></TopSpeciality>
          <StaticBanner></StaticBanner>
          <BestHospital></BestHospital>
          <BestPathology parentCallback={handleCallback}></BestPathology>
          <HomeCareStaticBanner></HomeCareStaticBanner>
          <HomeCareService></HomeCareService>
          <AmbulanceService></AmbulanceService>
          {/* <FeedbackModule></FeedbackModule> */}
          <BestDoctor></BestDoctor>
          <OfferSubscription></OfferSubscription>
          <Footer></Footer>
        </div>
        
      </section>
      
    </div>
  );
}
