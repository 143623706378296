import React from 'react';
import Button from '../../UI/UIComponent/Button';
import Card from '../../UI/UIComponent/Card';
import StaticData from "../../StaticData/staticData.json";
import { faArrowRight } from "@fortawesome/fontawesome-free-solid";

export default function HomeCareService() {
  const imgPath = require.context('../../assets/Images/Homepage/Bestservice/Homecare',true);
    const handleClickReadMore = () => {
        let modalRef = document.getElementById("commonModal");
        modalRef.classList.add("show");
      };

  return (
    <div className="bestService">
      <div className="container-fluid">
        <div className="container p-0">
          <div className="global-heading row">
            <div className="col-lg-8 p-0 col-md-12">
              <h2>{StaticData.Homecare.Heading}</h2>
              <span>
                {StaticData.Homecare.Subheading}
              </span>
            </div>
            <div className="col-lg-4 p-0 text-position col-md-12">
              <Button primaryButton={true}>{StaticData.Homecare.Button_Text}</Button>
            </div>
          </div>
        </div>
        <div className="best-Service">
          <div className="card-section container">
            {StaticData.Homecare.Services.map((data, index) => (
              <Card
                imagesrc={imgPath(`./${data.Service_Image}.jpg`)}
                imgAltText={data.Service_ImageAltText}
                heading={data.Service_Name}
                description={data.Service_Desc}
                CTAText="Read more"
                ButtonClass="buttonText"
                fontIcon={faArrowRight}
                handleCallToaction={handleClickReadMore}
              ></Card>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
