import { React, useEffect } from 'react'
import Card from '../../UI/UIComponent/Card'
import Button from '../../UI/UIComponent/Button'
import './bestFeatures.scss'
import { faArrowRight, faHospital } from '@fortawesome/fontawesome-free-solid'
import { useState } from 'react';
import { getBestHospitals } from '../../api/services/homepage-service';

export default function BestHospital() {

  const [BestHospitalList, setHospitalList] = useState([]);

  useEffect(() => {
    // fetch('http://localhost:4000/app/getAllHospitals')
    // .then((res) => {
    //   return res.json();
    // })
    // .then((data) => {
    //   console.log(data.data);
    //   setHospitalList(data.data);
    // });
    getBestHospitals().then(data => setHospitalList(data));
  }, []);
  const handleClickReadMore = () => {
    let modalRef = document.getElementById('commonModal')
    modalRef.classList.add('show')
  }
  return (
    <div className="bestService">
      <div className="container-fluid">
        <div className="container p-0">
          <div className="global-heading row">
            <div className="col-lg-8 p-0 col-md-12">
              <h2>Top Associate Hospital</h2>
              <span>
                Rever Health Solution partners with top hospitals for
                round-the-clock surgical,medical, dailysis and emergency
                critical care services, ensuring continious access to
                high-quality healthcare.
              </span>
            </div>
            <div className="col-lg-4 p-0 text-position col-md-12">
              <Button primaryButton={true}>View All Specialities</Button>
            </div>
          </div>
        </div>
        <div className="best-Service">
          <div className="card-section container p-0">
            {BestHospitalList.map((data, index) => (
              <Card
                imagesrc={data.HospitalPhoto}
                imgAltText="hospital-img"
                heading={data.HospitalName}
                description={`${data.Address[0].street}${' '}${data.Address[0].city}${' '}${data.Address[0].sate}${' '}${data.Address[0].pin}`}
                CTAText="Read more"
                ButtonClass="buttonText"
                fontIcon={faArrowRight}
                handleCallToaction={handleClickReadMore}
              ></Card>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
