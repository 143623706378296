import React, { useEffect, useState, useReducer } from "react";
import Card from "../../UI/UIComponent/Card";
import Button from "../../UI/UIComponent/Button";
import InputBox from "../../UI/UIComponent/Input";
import "./bestFeatures.scss";
import BestHospitalList from "../../StaticData/BestHospital.json";
import pathologyBanner from "../../assets/Images/Homepage/pathologyBanner.jpg";
//images
import appointment from "../../assets/Images/Homepage/Bestservice/LabTest/appointment.svg";
import findTest from "../../assets/Images/Homepage/Bestservice/LabTest/appointment.svg";
import downReport from "../../assets/Images/Homepage/Bestservice/LabTest/findTest.svg";
import homeCollection from "../../assets/Images/Homepage/Bestservice/LabTest/homeCollection.svg";
import nearSeller from "../../assets/Images/Homepage/Bestservice/LabTest/nearSeller.svg";

import { faArrowRight, faHospital } from "@fortawesome/fontawesome-free-solid";
import { json } from "react-router-dom";
import { getBestPathology } from "../../api/services/homepage-service";
import { getOTP } from "../../api/services/global-service";

const FirstNameReducer = (state, action) => {
  if (action.type === "user_firstName") {
    return { value: action.value, isFirstNameValid: action.value.length > 0 };
  }
};
const EmailReducer = (state, action) => {
  if (action.type === "user_email") {
    return { value: action.value, isEmailValid: action.value.includes("@") };
  }
};
const PhoneNoReducer = (state, action) => {
  if (action.type === "user_phone") {
    return { value: action.value, isPhoneNoValid: action.value.length === 10 };
  }
};
const PrescriptionReducer = (state, action) => {
  if (action.type === "user_prescription") {
    return {
      value: action.value,
      isprescriptionValid: action.value.length > 0,
    };
  }
};
const zipcodeReducer = (sate, action) => {
  if (action.type === "user_zip") {
    return { value: action.value, iszipcodeValid: action.value.length === 6 };
  }
};
const cityReducer = (sate, action) => {
  if (action.type === "user_city") {
    return { value: action.value, isCityValid: action.value.length >= 3 };
  }
};

export default function BestPathology(props) {
  const [setFirstName, dispatchFirstNameEvent] = useReducer(FirstNameReducer, {
    value: "",
    isFirstNameValid: null,
  });
  const [setEmail, dispatchEmailEvent] = useReducer(EmailReducer, {
    value: "",
    isLastNameValid: null,
  });
  const [setPhone, dispatchPhoneNumberEvent] = useReducer(PhoneNoReducer, {
    value: "",
    isPhoneNoValid: null,
  });
  const [setPrescription, dispatchPrescriptionEvent] = useReducer(
    PrescriptionReducer,
    {
      value: "",
      isprescriptionValid: null,
    }
  );
  const [setzipCode, dispatchZipcodeEvent] = useReducer(zipcodeReducer, {
    value: "",
    iszipcodeValid: null,
  });
  const [setcity, dispatchCityEvent] = useReducer(cityReducer, {
    value: "",
    isCityValid: null,
  });
  const [fromValid, setFromValid] = useState(false);
  const [encodeData, setEncodedData] = useState("");
  const [BestPathologyData,setBestPathologyData] = useState([]);

  const onFirstNameChange = (e) => {
    dispatchFirstNameEvent({
      value: e.target.value,
      type: "user_firstName",
    });
  };
  const onEmailChange = (e) => {
    dispatchEmailEvent({
      value: e.target.value,
      type: "user_email",
    });
  };
  const onPhoneNumberChange = (e) => {
    dispatchPhoneNumberEvent({
      value: e.target.value,
      type: "user_phone",
    });
  };
  const onPrescriptionUpload = (e) => {
    let fileName = e.target.files[0];
    //let fileType = fileName.type;
    let reader = new FileReader();
    let base64String;

    reader.onload = function () {
      base64String = reader.result;

      // imageBase64Stringsep = base64String;

      console.log(base64String);
      let inputTypeRef = document.getElementById("fileUpload");
      inputTypeRef.setAttribute("data-value", base64String);
    };
    reader.readAsDataURL(fileName);
    dispatchPrescriptionEvent({
      value: e.target.value,
      type: "user_prescription",
    });
  };
  const onZipcodeChange = (e) => {
    dispatchZipcodeEvent({
      value: e.target.value,
      type: "user_zip",
    });
  };
  const onCityChange = (e) => {
    dispatchCityEvent({
      value: e.target.value,
      type: "user_city",
    });
  };
  const handleNameRelease = (e) => {
    if (firstNamevalid) {
      document.getElementById("idFirstName").classList.remove("input-error");
    } else {
      document.getElementById("idFirstName").classList.add("input-error");
    }
  };
  const handleEmailRelease = (e) => {
    if (emailvalid) {
      document.getElementById("idEmail").classList.remove("input-error");
    } else {
      document.getElementById("idEmail").classList.add("input-error");
    }
  };
  const handlePhoneRelease = (e) => {
    if (phonevalid) {
      document.getElementById("idPhoneNo").classList.remove("input-error");
    } else {
      document.getElementById("idPhoneNo").classList.add("input-error");
    }
  };
  const handlePrescriptionRelease = (e) => {
    if (prescriptionValid) {
      document.getElementById("idPrescription").classList.remove("input-error");
    } else {
      document.getElementById("idPrescription").classList.add("input-error");
    }
  };
  const handleZipRelease = (e) => {
    if (zipcodeValid) {
      document.getElementById("idZip").classList.remove("input-error");
    } else {
      document.getElementById("idZip").classList.add("input-error");
    }
  };
  const handleCityRelease = (e) => {
    if (cityValid) {
      document.getElementById("idcity").classList.remove("input-error");
    } else {
      document.getElementById("idcity").classList.add("input-error");
    }
  };

  const { isFirstNameValid: firstNamevalid } = setFirstName;
  const { isEmailValid: emailvalid } = setEmail;
  const { isPhoneNoValid: phonevalid } = setPhone;
  const { isprescriptionValid: prescriptionValid } = setPrescription;
  const { iszipcodeValid: zipcodeValid } = setzipCode;
  const { isCityValid: cityValid } = setcity;

  useEffect(() => {
    getBestPathology().then(data => setBestPathologyData(data));
    if (!firstNamevalid) {
      document.getElementById("idFirstName").classList.remove("input-error");
    }
    if (!emailvalid) {
      document.getElementById("idEmail").classList.remove("input-error");
    }
    if (!phonevalid) {
      document.getElementById("idPhoneNo").classList.remove("input-error");
    }
    if (!prescriptionValid) {
      document.getElementById("idPrescription").classList.remove("input-error");
    } else {
      let fileName = setPrescription.value.split("\\")[2];
      document.getElementById("idPrescription").value = fileName;
    }
    if (!zipcodeValid) {
      document.getElementById("idZip").classList.remove("input-error");
    }
    if (!cityValid) {
      document.getElementById("idcity").classList.remove("input-error");
    }
    setFromValid(
      firstNamevalid &&
        emailvalid &&
        phonevalid &&
        prescriptionValid &&
        zipcodeValid &&
        cityValid
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstNamevalid,
    emailvalid,
    phonevalid,
    prescriptionValid,
    zipcodeValid,
    cityValid,
  ]);
  const onFlieUploaderCLick = () => {
    document.getElementById("fileUpload").click();
  };
  const onPrescriptionUploadDispatch = () => {
    document.getElementById("fileUpload").dispatchEvent(onPrescriptionUpload);
  };
  const handleClickReadMore = () => {
    let modalRef = document.getElementById("commonModal");
    modalRef.classList.add("show");
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();
    var encodedData;
    const payload = {
      patient_name: setFirstName.value,
      patient_email: setEmail.value,
      patient_contactNo: setPhone.value,
      patient_prescription: document.getElementById("fileUpload").getAttribute("data-value"),
      patient_pin: setzipCode.value,
      patient_city: setcity.value,
      query_type:"Pathology Service",
      queryRaisedOn: new Date()
    };
    let modalRef = document.getElementById("otpModal");
    if (fromValid) {
      const returnPayload = await handleRequestOtp({
        phone_Number: payload.patient_contactNo,
        type: "phone",
      });

      setEncodedData(returnPayload.data);
      encodedData = returnPayload.data
      props.parentCallback({ ...payload, encodedData });
      document
        .getElementById("enter_otp")
        .setAttribute("data-value", returnPayload.data);
      modalRef.classList.add("show");

      dispatchFirstNameEvent({
        value: "",
        type: "user_firstName",
      });
      dispatchEmailEvent({
        value: "",
        type: "user_email",
      });
      dispatchPhoneNumberEvent({
        value: "",
        type: "user_phone",
      });
      dispatchZipcodeEvent({
        value: "",
        type: "user_zip",
      });
      dispatchCityEvent({
        value: "",
        type: "user_city",
      });
      dispatchPrescriptionEvent({
        value: "",
        type: "user_prescription",
      });
      document.getElementById("idFirstName").value = "";
      document.getElementById("idEmail").value = "";
      document.getElementById("idPhoneNo").value = "";
      document.getElementById("idZip").value = "";
      document.getElementById("idcity").value = "";
      document.getElementById("idPrescription").value = "";
      document.getElementById("fileUpload").setAttribute("data-value", "");
      //setzipCode.value = '';
      // setcity.value = '';
    } else {
      if (!firstNamevalid) {
        document.getElementById("idFirstName").classList.add("input-error");
      } else if (!emailvalid) {
        document.getElementById("idEmail").classList.add("input-error");
      } else if (!phonevalid) {
        document.getElementById("idPhoneNo").classList.add("input-error");
      } else if (!prescriptionValid) {
      } else if (!zipcodeValid) {
        document.getElementById("idZip").classList.add("input-error");
      } else if (!cityValid) {
        document.getElementById("idcity").classList.add("input-error");
      }
    }
  };
  const handleRequestOtp = async (payload) => {
    const data = await getOTP(payload);
    return data;
  };
  const submitFormData = async (payload) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          patient_name: payload.patient_name,
          patient_email: payload.patient_email,
          patient_contactNo: payload.patient_contactNo,
          patient_prescription: payload.patient_prescription,
          patient_pin: payload.patient_pin,
          patient_city: payload.patient_city,
        }),
      };
      const response = await fetch(
        "http://localhost:4000/app/submitPatientQuery",
        requestOptions
      );
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      throw error;
    }
  };
  return (
    <>
      <div className="bestService pathology">
        <div className="container-fluid p-0">
          <div className="row custom-row">
            <div className="col-lg-7 col-md-12">
              <div className="lab-test-query">
                <div className="left-banner">
                  <img
                    src={pathologyBanner}
                    alt="RHS pathology service"
                    className="path-banner"
                    
                  ></img>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="query-form container">
                <div className="row">
                  <div className="form-heading col-12">
                    <span>Lab Test From The Comfort Of Your Home</span>
                  </div>
                </div>
                <div className="row">
                  <form onSubmit="">
                    <div className="row custom-row">
                      <div className="col-lg-12">
                        <InputBox
                          inputId="idFirstName"
                          inputPlaceholder="Enter Your Name"
                          handleonChange={onFirstNameChange}
                          handleInputBlur={handleNameRelease}
                        ></InputBox>
                      </div>
                      <div className="col-lg-12">
                        <InputBox
                          inputId="idEmail"
                          inputPlaceholder="Please Enter Your Email"
                          onChange=""
                          onBlur=""
                          inputType="email"
                          handleonChange={onEmailChange}
                          handleInputBlur={handleEmailRelease}
                        ></InputBox>
                      </div>
                      <div className="col-lg-12">
                        <InputBox
                          inputId="idPhoneNo"
                          inputPlaceholder="Please Enter Phone Number"
                          handleonChange={onPhoneNumberChange}
                          handleInputBlur={handlePhoneRelease}
                          inputType="number"
                        ></InputBox>
                      </div>

                      <div className="col-lg-12">
                        <InputBox
                          inputId="idPrescription"
                          inputPlaceholder="Upload your prescription"
                          handleonChange={onPrescriptionUploadDispatch}
                          handleInputClick={onFlieUploaderCLick}
                          handleInputBlur={handlePrescriptionRelease}
                        ></InputBox>
                        <input
                          hidden
                          type="file"
                          id="fileUpload"
                          onChange={onPrescriptionUpload}
                          inputPlaceholder="Upload your prescription"
                        ></input>
                        {/* <input type="file" id="fileUpload" hidden></input> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <InputBox
                          inputId="idZip"
                          inputPlaceholder="Please Enter Zipcode"
                          handleonChange={onZipcodeChange}
                          handleInputBlur={handleZipRelease}
                          inputType="number"
                        ></InputBox>
                      </div>
                      <div className="col-6">
                        <InputBox
                          inputId="idcity"
                          inputPlaceholder="Please Enter City"
                          handleonChange={onCityChange}
                          handleInputBlur={handleCityRelease}
                        ></InputBox>
                      </div>
                    </div>
                    <div className="button-section">
                      <Button buttonClickHandler={handleButtonClick}>
                        Submit
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row path-at-a-glance">
            <div className="path-item-card">
              <div className="text-center">
                <img alt="" src={homeCollection} className="icon"></img>
              </div>

              <div className="text-center">
                <span>Book A Home Collection</span>
              </div>
            </div>
            <div className="path-item-card">
              <div className="text-center">
                <img alt="" src={appointment} className="icon"></img>
              </div>

              <div className="text-center">
                <span>Book A Appointment</span>
              </div>
            </div>
            <div className="path-item-card">
              <div className="text-center">
                <img alt="" src={findTest} className="icon"></img>
              </div>
              <div className="text-center">
                <span>Find A Test</span>
              </div>
            </div>
            <div className="path-item-card">
              <div className="text-center">
                <img alt="" src={downReport} className="icon"></img>
              </div>

              <div className="text-center">
                <span>Download Report</span>
              </div>
            </div>
            <div className="path-item-card">
              <div className="text-center">
                <img alt="" src={nearSeller} className="icon"></img>
              </div>

              <div className="text-center">
                <span>Find Your Nearest Seller</span>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="global-heading row">
              <div className="col-lg-8 p-0 col-md-12">
                <h2>Our Associate Pathology & Diagnostics</h2>
                <span>
                  Rever Health Solutions partners with premier pathology and
                  diagnostics centers accross West Bengal,ensuring patients
                  receive top-notch and accurate diagnoses for optimal health
                  outcomes.
                </span>
              </div>
              <div className="col-lg-4 text-position p-0 col-md-12">
                <Button primaryButton={true}>View All Specialities</Button>
              </div>
            </div>
          </div>
          <div className="best-Service">
            <div className="card-section">
              {BestPathologyData.map((data, index) => (
                <Card
                  imagesrc={data.pathologyPhoto}
                  imgAltText="hospital-img"
                  heading={data.PathologyName}
                  description={`${data.Address[0].street}${' '}${data.Address[0].city}${' '}${data.Address[0].state}${' '}${data.Address[0].pin}`}
                  CTAText="Read more"
                  ButtonClass="buttonText"
                  fontIcon={faArrowRight}
                  handleCallToaction={handleClickReadMore}
                ></Card>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
