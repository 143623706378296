import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../RHS_logo.png";
import facebookLogo from "../assets/Images/Svg/facebook-logo.png";
import instaLogo from "../assets/Images/Svg/insta-logo.png";
import "./footer.scss";

export default function Footer() {
  return (
    <footer className="footer container-fluid">
      <div className="top-section">
        <ul>
          <li>
            <Link class="" to="/">
              RHS
            </Link>
            <div className="contact-number">
              <a href="/">About</a>
              <a href="/">Medical Tips</a>
              <a href="/">Press</a>
              <a href="/">Contact Us</a>
            </div>
          </li>
          <li>
            <Link class="" to="/about">
              For Patients
            </Link>
            <div className="contact-number">
              <a href="/">Search For Doctors</a>
              <a href="/">Search For Clinics</a>
              <a href="/">Search For Hospitals</a>
              <a href="/">Book Diagnostics Tests</a>
              <a href="/">Book Full Body Checkups</a>
              <a href="/">Book Ambulence Services</a>
              <a href="/">Book Your Medical Tourism</a>
              <a href="/">Book Your Physiotherapists</a>
              <a href="/">Book Your Blood Sample collection</a>
              <a href="/">RHS Membership Card</a>
              <a href="/">Read Health Articles</a>
              <a href="/">Read About Medicines</a>
            </div>
          </li>
          <li>
            <Link to="/">Signup Profile</Link>
            <div className="contact-number">
              <a href="/">For Doctors</a>
              <a href="/">For Hospitals</a>
              <a href="/">For Diagnostics Clinics</a>
              <a href="/">For Ambulance Provider</a>
              <a href="/">For Nurse</a>
              <a href="/">For Physiotherapists</a>
            </div>
          </li>
          <li>
            <Link to="/">Medical Tourism</Link>
          </li>
          <li>
            <a href="/">For Corporates</a>
            <div className="contact-number">
              <a href="/">FAQ</a>
              <a href="/">Privacy Pocily</a>
              <a href="/terms&conditions">Terms & Conditions</a>
              <a href="/">PCS T&C</a>
            </div>
          </li>
          <li>
            <a href="/">Social</a>
            <div className="social-icons">
              <a
                target="_blank"
                href="https://www.facebook.com/reverhealthsolution"
                rel="noreferrer"
              >
                <img src={facebookLogo} alt="Rever Health Facebook"></img>
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/reverhealthsolution/"
                rel="noreferrer"
              >
                <img src={instaLogo} alt="Rever Health Instagram"></img>
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div className="bottom-section">
        <img src={logo} alt="Rever Health Solution"></img>
        <a href="/">© 2023 Rever Health Solution, Kolkata-700042, India</a>
      </div>
    </footer>
  );
}
